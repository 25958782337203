import React from 'react';
import ReactDOM from 'react-dom';
import * as Config from "user/helpers/config";
import {AchievementToast} from "./AchievementToast";

const achievementToastElement = document.getElementById('achievement-toast');


if (achievementToastElement) {
    Config.initConfig(achievementToastElement.dataset);
    ReactDOM.render(<AchievementToast />, achievementToastElement);
}